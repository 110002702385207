import { Component, OnInit, isDevMode } from '@angular/core';
// import * as $ from 'jquery';
// import * as jQuery from 'jquery';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './services/Auth/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title1 = 'angular-idle-timeout';
  constructor(private router:Router,private AuthService:AuthService,private idle: Idle, private keepalive: Keepalive){
   // sets an idle timeout of 5 seconds, for testing purposes.
   idle.setIdle(1);
   // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
   idle.setTimeout(300);
   // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
   idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
   let token = localStorage.getItem('token');
   if(token){
     idle.watch();
    }else{
     idle.stop();
    }
   idle.onIdleEnd.subscribe(() => { 
     this.idleState = 'No longer idle.'
    //  console.log(this.idleState,"aaaa");
     this.reset();
   });
   
   idle.onTimeout.subscribe(() => {
     this.idleState = 'Timed out!';
     this.timedOut = true;
    //  console.log(this.idleState,"bbb");
     // this.router.navigate(['/']);
     this.signOut()
   });
   
   idle.onIdleStart.subscribe(() => {
       this.idleState = 'You\'ve gone idle!'
      //  console.log(this.idleState,"shshshshs");
   });
   idle.onTimeoutWarning.subscribe((countdown) => {
     this.idleState = 'You will time out in ' + countdown + ' seconds!'
    //  console.log(this.idleState,"kamal");
   });
   // sets the ping interval to 15 seconds
   keepalive.interval(15);
   keepalive.onPing.subscribe(() => this.lastPing = new Date());
   this.AuthService.getUserLoggedIn().subscribe(userLoggedIn => {
     if (userLoggedIn) {
       idle.watch()
       this.timedOut = false;
     } else {
      idle.stop()
     }
   })
  }
  ngOnInit() {
    if (isDevMode()) {
      console.log('Development!');
    } else {
      console.log('Production!');
    }

    
  }
  title = 'admin';


 

 signOut() {
    this.AuthService.logout().subscribe(
      (res: any) => {        
        this.router.navigate(['/']);
        let myItem = window.localStorage.getItem('user##');
        window.localStorage.clear();
        if(myItem) window.localStorage.setItem('user##',myItem);
      });
  
 }

 reset() {
   this.idle.watch();
   this.idleState = 'Started.';
   this.timedOut = false;
 }
}
