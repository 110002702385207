import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { AuthService } from '../Auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private headersData:any = {};

  private apiUrl:any = {
    lastMonthPolicies: apiBaseUrls + 'dashboard/getLastMonthsCounts/',
    lastMonthCustomers: apiBaseUrls + 'dashboard/getLastMonthsCounts/',
    lastMonthProducts: apiBaseUrls + 'dashboard/getLastMonthsCounts/',
    lastMonthQuotes: apiBaseUrls + 'dashboard/getLastMonthsCounts/',
    lastMonthCompanies: apiBaseUrls + 'dashboard/getLastMonthsCounts/',
    lastMonthCompanies_: apiBaseUrls + 'dashboard/getLastMonthsCounts_/',

    lastMonthPolicies_: apiBaseUrls + 'dashboard/getLastMonthsCounts_/',
    lastMonthCustomers_: apiBaseUrls + 'dashboard/getLastMonthsCounts_/',
    lastMonthProducts_: apiBaseUrls + 'dashboard/getLastMonthsCounts_/',
    lastMonthQuotes_: apiBaseUrls + 'dashboard/getLastMonthsCounts_/',
  };
  
  constructor(private authService: AuthService, private http: HttpClient) {
  }

  apiCall(apiType:any, formData:any = null) {
    let Url = this.apiUrl[apiType] + apiType;

    this.headersData = this.authService.setTokenHeader();
    if (apiType.endsWith('_') && formData && formData.id) {
    Url += `/${formData.id}`;
  }
    switch(apiType) {
      case 'lastMonthPolicies':
        return this.http.get(Url, this.headersData);
      break;

      case 'lastMonthCustomers':
        return this.http.get(Url, this.headersData);
      break;

      case 'lastMonthProducts':
        return this.http.get(Url, this.headersData);
      break;

      case 'lastMonthQuotes':
        return this.http.get(Url, this.headersData);
      break;
      case 'lastMonthPolicies_':
        return this.http.get(Url, this.headersData);
      break;

      case 'lastMonthCustomers_':
        return this.http.get(Url, this.headersData);
      break;

      case 'lastMonthProducts_':
        return this.http.get(Url, this.headersData);
      break;

      case 'lastMonthQuotes_':
        return this.http.get(Url, this.headersData);
      break;
      case 'lastMonthCompanies_':
        return this.http.get(Url, this.headersData);
      break;

      case 'lastMonthCompanies':
      default:
        return this.http.get(Url, this.headersData);
      break;
    }
  }
}
