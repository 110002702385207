import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { AuthService } from '../Auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AgentService {
  private headersData:any = {};
  
  private apiUrl:any = {
    listing: apiBaseUrls + 'agent/list',
    listing_agent: apiBaseUrls + 'commission/agent_amount',
    addPaymentHistory: apiBaseUrls + 'commission/addPaymentHistory',
    PaymentHistory: apiBaseUrls + 'commission/PaymentHistory',

    CommissionHisotry: apiBaseUrls + 'commission/CommissionHisotry',


    
    create: apiBaseUrls + 'agent/create',
    show: apiBaseUrls + 'agent/show/',
    update: apiBaseUrls + 'agent/update/',
    delete: apiBaseUrls + 'agent/delete/',
    roles: apiBaseUrls + 'agent/roles',
    companies: apiBaseUrls + 'company/list',
    managers: apiBaseUrls + 'agent/managers/',    
    departments: apiBaseUrls + 'agent/departments',
    delete_roles: apiBaseUrls + 'agent/deleteRoles/',
    create_customer: apiBaseUrls + 'agent/create_customer/',
    list: apiBaseUrls + 'agent/list/',
  };
  
  constructor(private authService: AuthService, private http: HttpClient) {
  }
  

  apiCall(apiType:any, formData:any = null) {
    let Url = this.apiUrl[apiType];

    this.headersData = this.authService.setTokenHeader();
    
    switch(apiType) {
      case 'create':
        return this.http.post(Url, formData, this.headersData);
      break;

      case 'show':
        Url = Url + formData;
        return this.http.get(Url, this.headersData);
      break;

      case 'update':
        Url = Url + formData.id;
        return this.http.put(Url, formData, this.headersData);
      break;

      case 'delete':
        Url = Url + formData;
        return this.http.delete(Url, this.headersData);
      break;

      case 'PaymentHistory':
        Url = Url ;
        return this.http.post(Url, formData, this.headersData);
      break;


      case 'CommissionHisotry':
        Url = Url ;
        return this.http.post(Url, formData, this.headersData);
      break;



      

      case 'listing':
      default:
        return this.http.post(Url, formData, this.headersData);
      break;

      

      case 'companies':
        Url = Url;
        return this.http.post(Url, formData, this.headersData);
      break;

      case 'managers':
        Url = Url + formData;
        return this.http.get(Url, this.headersData);
      break;

      case 'departments':
        Url = Url;
        return this.http.get(Url, this.headersData);
      break;

      case 'roles':
        Url = Url;
        return this.http.get(Url, this.headersData);
      break;

      case 'delete_roles':
        Url = Url + formData;
        return this.http.delete(Url, this.headersData);
      break;

      case 'create_customer':
        Url = Url;
        return this.http.post("https://adminapi.redinsure.redianglobal.com/agent/create", formData, this.headersData);
      break;

      case 'list':
        Url = Url;
        return this.http.post("https://adminapi.redinsure.redianglobal.com/agent/list", formData, this.headersData);
      break;
    }
  }
	  
}
