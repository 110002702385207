import { Component, OnInit } from '@angular/core';
import { DashboardService as DataService} from '../../services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  private formData = {};
  public lastMonthPolicies:any = 0;
  public lastMonthCustomers:any = 0;  
  public lastMonthCompanies:any = 0;  
  public lastMonthQuotes:any = 0;
    
  constructor(private service: DataService) { }
isAgent = false;
  userType:string;
  ngOnInit() {
    let db = localStorage.getItem("agent##");
    if(db) this.isAgent = true;
    console.log(localStorage.getItem('user##'),'anshu');
    let data = localStorage.getItem('user##');
    this.userType = JSON.parse(data);
    console.log(this.userType,'anshukumar');
    this.formData['id'] = db || '';

    this.service.apiCall(db ? 'lastMonthPolicies_':'lastMonthPolicies', this.formData).subscribe(
      (res: any) => {
        if(res.success) {
          this.lastMonthPolicies = res.data.records[0].CNT;      
        }
    });

    this.service.apiCall(db ? 'lastMonthCustomers_':'lastMonthCustomers', this.formData).subscribe(
      (res: any) => {
        if(res.success) {
          this.lastMonthCustomers = res.data.records[0].CNT;
        }
    });
    if(!db){
      this.service.apiCall(db ? 'lastMonthCompanies_':'lastMonthCompanies', this.formData).subscribe(
        (res: any) => {
          if(res.success) {
            this.lastMonthCompanies = res.data.records[0].CNT;      
          }
      });
    }
   

    this.service.apiCall(db ? 'lastMonthQuotes':'lastMonthQuotes', this.formData).subscribe(
      (res: any) => {
        if(res.success) {
          this.lastMonthQuotes = res.data.records[0].CNT;      
        }
    });

   
  }
}
