import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { AuthService } from '../Auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {
  private headersData:any = {};

  private apiUrl:any = {
    listing: apiBaseUrls + 'commission/list',
    create: apiBaseUrls + 'commission/create',
    create_: apiBaseUrls + 'inspection/create',
    show: apiBaseUrls + 'commission/show/',
    update: apiBaseUrls + 'commission/updates/',
    delete: apiBaseUrls + 'commission/delete/',
    roles: apiBaseUrls + 'commission/roles',
    companies: apiBaseUrls + 'company/list',
    managers: apiBaseUrls + 'commission/managers/',    
    departments: apiBaseUrls + 'commission/departments',
    delete_roles: apiBaseUrls + 'commission/deleteRoles/',
  };
  
  constructor(private authService: AuthService, private http: HttpClient) {
  }
  

  apiCall(apiType:any, formData:any = null) {
    let Url = this.apiUrl[apiType];

    this.headersData = this.authService.setTokenHeader();
    
    switch(apiType) {
      case 'create':
        return this.http.post(Url, formData, this.headersData);
      break;

      case 'show':
        Url = Url + formData;
        return this.http.get(Url, this.headersData);
      break;

      case 'update':
        Url = Url + formData.id;
        return this.http.put(Url, formData, this.headersData);
      break;

      case 'delete':
        Url = Url + formData;
        return this.http.delete(Url, this.headersData);
      break;

      case 'listing':
      default:
        return this.http.post(Url, formData, this.headersData);
      break;

      case 'companies':
        Url = Url;
        return this.http.post(Url, formData, this.headersData);
      break;

      case 'managers':
        Url = Url + formData;
        return this.http.get(Url, this.headersData);
      break;

      case 'departments':
        Url = Url;
        return this.http.get(Url, this.headersData);
      break;

      case 'roles':
        Url = Url;
        return this.http.get(Url, this.headersData);
      break;

      case 'delete_roles':
        Url = Url + formData;
        return this.http.delete(Url, this.headersData);
      break;
    }
  }
	  
}
