import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddComponent } from './components/add/add.component';
import { ListComponent } from './components/list/list.component';
import { EditComponent } from './components/edit/edit.component';
import { CalendarComponent } from './components/calendar/calendar/calendar.component';

const routes: Routes = [
	{
		path: '',
		component:ListComponent ,
		pathMatch: 'full',
		
	},
	 {
        path: 'add',
        component: AddComponent,
        pathMatch: 'full'
    },
    {
      path: 'calendar',
      component: CalendarComponent,
      pathMatch: 'full'
  },
	{
        path: 'edit/:id',
        component: EditComponent,
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class inspectionRoutingModule { }
