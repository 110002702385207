import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgentService } from 'src/app/services/agent-management/agent.service';
import { CommissionService } from 'src/app/services/commission/commission.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service_agent: AgentService,
    private commission_serv: CommissionService,


    private toastr: ToastrService) { }

  users = ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'];

  // Initialize InspectionManager object with default values
  inspectionManager = {
    name: '',
    postalCode: '',
    agencyAddress: '',
    agencyEmail: '',
    agencyPhone: '',
    duration:1,
    staffList: [],
    timings: this.initializeTimings(),
    selectedUsers: []
  };
  private apiType: string = 'create_';
  sameTimingForAll: boolean = true;

  commonTiming = { start: '', startMin: '', end: '', endMin: '' };

  // Apply same timing to all days
  ngOnInit(){
    this.applySameTimingToAll();
  }
  applySameTimingToAll(): void {
    if (this.sameTimingForAll) {
      const commonTiming = { ...this.commonTiming };

      for (const day of this.days) {
        this.inspectionManager.timings[day] = {
          ...commonTiming,
          fullDayOff: false,
          selected: true
        };
      }
    }
  }

  // Initialize timings for each day
  initializeTimings() {
    this.days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    return this.days.reduce((acc, day) => {
      acc[day] = { fullDayOff: false, start: '', startMin: '', end: '', endMin: '' };
      return acc;
    }, {});
  }

  // Validate the form
  isFormValid(): boolean {
    return this.isNameValid() &&
      this.isPostalCodeValid() &&
      this.isPhoneValid() &&
      this.isEmailValid()
  }

  // Check if the name is valid
  isNameValid(): boolean {
    return this.inspectionManager.name.trim() !== '';
  }

  // Check if the postal code is valid (numeric validation)
  isPostalCodeValid(): boolean {
    const postalCodePattern = /^[0-9]{5,6}$/; // assuming a 5-6 digit postal code
    return postalCodePattern.test(this.inspectionManager.postalCode);
  }

  // Check if the phone number is valid (numeric validation)
  isPhoneValid(): boolean {
    const phonePattern = /^[0-9]{10}$/; // assuming a 10-digit phone number
    return phonePattern.test(this.inspectionManager.agencyPhone);
  }

  // Check if the email is valid
  isEmailValid(): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(this.inspectionManager.agencyEmail);
  }

  // Check if the timings are valid (not empty) unless the day is marked as full day off
  areTimingsValid(): boolean {
    for (let day of this.days) {
      const timing = this.inspectionManager.timings[day];
      if (!timing.fullDayOff) {
        if (!timing.start || !timing.end || !timing.startMin || !timing.endMin) {
          return false;
        }
      }
    }
    return true;
  }

  // Handle Full Day Off toggle (disable times if full day off is selected)
  toggleDayOff(day: string): void {
    const timing = this.inspectionManager.timings[day.toLowerCase()];
    if (timing.fullDayOff) {
      timing.start = '';
      timing.startMin = '00';
      timing.end = '';
      timing.endMin = '00';
    }
  }

  timeOptions = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes = ['00', '15', '30', '45'];
  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  // Add a new staff member
  addStaff() {
    this.inspectionManager.staffList.push({ name: '',email:"", phone: '' });
  }

  // Remove a staff member
  removeStaff(index: number) {
    this.inspectionManager.staffList.splice(index, 1);
  }

  
  onSubmit(form: NgForm): void {
    let apiState= 'saveContinue';
    if (form.valid && this.isFormValid()) {
      console.log('Form submitted successfully:', this.inspectionManager);
      this.commission_serv.apiCall(this.apiType, this.inspectionManager).subscribe(
        (res: any) => {
          if (res.success === true) {
            this.toastr.success(res.message, "Success");
            if (apiState === 'saveContinue') {
              // if(this.apiType == 'create') {
                // this.router.navigate([this.routeUrl.edit+res.data.records[0].insertId]);
                this.router.navigate(['/admin/inspection']);
              // }
            } else if (apiState === 'savenewAdd') {
              // this.router.navigate([this.routeUrl.add]);
            } else if (apiState === 'saveExit') {
              // this.router.navigate([this.routeUrl.listing]);
            }
          } else {
            this.toastr.error(res.message, "Error");
          }
        });
    } else {
      console.log('Form is not valid!');
      alert('Please fill in all required fields and valid timings!');
    }
  }

}

  
