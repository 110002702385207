import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgForm, FormControl } from "@angular/forms";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '../../services/Auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AESEncryptDecryptService } from '../../services/aesencrypt-decrypt.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  private activeTab: any = "individual";
  private showed = false;
  public username: any;
  public password: any;
  public otp: any;
  private isLogin: boolean = false;
  private token: any;
  private user_type: any;
  private loginFail: boolean = false;

  constructor(private _AESEncryptDecryptService: AESEncryptDecryptService , private ngZone: NgZone, private _AuthService: AuthService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.showPassText[1] = 'Show';

    window.localStorage.getItem('token')
    if (window.localStorage.getItem('token')) {
      this.router.navigate(['admin/dashboard']);
    } else {
      this.router.navigate(['/']);
    }
let user  = window.localStorage.getItem('user##');
    if(user){
    user = JSON.parse(user);
    this.username = user['u'];
    let decryptedText = this._AESEncryptDecryptService.decrypt(user['p']);
     this.password =decryptedText;
     document.getElementById("remember_check")['checked'] = true;
     console.log(decryptedText,"kkkkkkkkkkk")
    }
  }
  showPassText: any = [];
  otpDiv=false;
  showPassword(input: any, index) {
    console.log("kamal")
    input.type = input.type == 'password' ? 'text' : 'password';
    this.showPassText[index] = input.type == 'password' ? 'Show' : 'Hide';
    console.log("sharma",this.showPassText)

  }
  otpL=false;
  onOtpChange(event){
    this.otp = event;
    console.log(this.otp.lengh,"this.otp.lenghthis.otp.lengh")
    if(event){
      let r = event.split('');
      if(r.length==6){
        console.log("kamal")
        this.otpL=true;
      }else{
        this.otpL=false;
      }
     
    }
    console.log(event,"kamal")
  }
  resendOtp(){
    let formData = {
      "username": this.username,
    }
    console.log("kamal")
    this._AuthService.resendOtp(formData).subscribe(
      (loginResponse: any) => {
        let loginRes: any = loginResponse;
        console.log(loginRes,"loginResloginRes")
        if (loginRes.success == true) {
          this.otpDiv =true;
          this.userName = loginResponse.data.records.username
          this.toastr.success(`OTP has been sent to ${loginResponse.data.records.email}`, '');
        } else if (loginRes.success == false) {
          this.toastr.error(loginRes.message, 'Error');
        }
      })
  }
  userName
  submit(){
    let formData = {
      "username": this.userName,
      "otp": this.otp
    }
    console.log(formData)
    this._AuthService.verifyOtp(formData).subscribe(
      (loginResponse: any) => {
        let loginRes: any = loginResponse;
        console.log(loginRes,"loginResloginRes")
        if (loginRes.success == true) {
    window.localStorage.setItem('token', loginResponse.data.token);
   window.localStorage.setItem('menuItem', JSON.stringify(loginResponse.data.menuObj));
   window.localStorage.setItem('displayName', loginResponse.data.displayName);
   if(loginResponse.data.agent_code && loginResponse.data.agent_code!="null"){
    window.localStorage.setItem('agent##', loginResponse.data.agent_code);

   }

   this._AuthService.setUserLoggedIn(true);
   this.token = window.localStorage.getItem('token');
  //  this._AuthService.expireTime();
   this.router.navigate(['admin/dashboard']);
        } else if (loginRes.success == false) {
          this.toastr.error(loginRes.message, 'Error');
        }
      })
  }

  login() {
    let formData = {
      "username": this.username,
      "password": this.password
    }
    this._AuthService.login(formData).subscribe(
      (loginResponse: any) => {
        let loginRes: any = loginResponse;
        console.log(loginRes,"loginResloginRes")
        if (loginRes.success == true) {
          this.otpDiv =true;
          this.userName = loginResponse.data.records.username
          this.toastr.success(`OTP has been sent to ${loginResponse.data.records.email}`, '');
        } else if (loginRes.success == false) {
          this.toastr.error(loginRes.message, 'Error');
        }
      })
  }


 
  logoutAdmin() {
    console.log("kamal settimeout")
    this._AuthService.logout().subscribe(
      (res: any) => {        
        this.router.navigate(['/']);
        this.toastr.success(res.message, 'Success');
        let myItem = window.localStorage.getItem('user##');
        window.localStorage.clear();
        if(myItem) window.localStorage.setItem('user##',myItem);
      });
  }

  rememberMe(e) {
    if (e.target.checked == true) {
      let encryptedPass = this._AESEncryptDecryptService.encrypt(this.password);
      let obj ={u:this.username,p:encryptedPass};
      
      window.localStorage.setItem('user##',JSON.stringify(obj));
    } else {
      localStorage.removeItem('user##')
      // this.username = "";
      // this.password = "";
    }

  }

}
