import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SingleColumnComponent } from './layouts/single-column/single-column.component';
import { TwoColumnComponent } from './layouts/two-column/two-column.component';
import { LoginComponent } from './modules/login/login.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ForgetPasswordComponent } from './modules/forget-password/forget-password.component'
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component'
import { AuthGuard } from './services/Auth/auth.guard'
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';

import { from } from 'rxjs';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
const routes: Routes = [

  {
    path: '',
    component: SingleColumnComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'admin/forget-password', component: ForgetPasswordComponent },
      { path: 'admin/reset-password/:token', component: ResetPasswordComponent },
      { path: 'admin/forget-password', component: ForgetPasswordComponent }
    ]
  },
  {
    path: 'admin',
    component: TwoColumnComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      //{ path: 'grid', component: TestingComponent },
      {
        path: "roles",
        loadChildren: '../app/modules/roles/roles.module#RolesModule'
      },
      {
        path: "users",
        loadChildren: '../app/modules/users/users.module#UsersModule'
      },
      {
        path: "agent",
        loadChildren: '../app/modules/agent/agent.module#AgentModule'
      },
      // new module Agent-customers
      {
        path: "agent-customers",
        loadChildren: '../app/modules/agent-customers/agent-customers.module#AgentCustomersModule'
      },
        {
          path: "agent-leads",
          loadChildren: '../app/modules/agent-lead/agent-lead.module#AgentLeadModule'
        },
          {
            path: "agent-quote",
            loadChildren: '../app/modules/agent-quote/agent-quote.module#AgentQuoteModule'
          },
            {
              path: "agent-policies",
              loadChildren: '../app/modules/agent-policies/agent-policies.module#AgentPoliciesModule'
            },
              {
                path: "agent-commision",
                loadChildren: '../app/modules/agent-commision/agent-commision.module#AgentCommisionModule'
              },  

      {
        path: "departments",
        loadChildren: '../app/modules/departments/departments.module#DepartmentsModule'
      },
      {
        path: "commission",
        loadChildren: '../app/modules/commission/commission/commission.module#CommissionModule'
      },
      {
        path: "cms",
        loadChildren: '../app/modules/cms/cms.module#CMSModule'
      },
      {
        path: "faq",
        loadChildren: '../app/modules/faq/faq.module#FaqModule'
      },
      {
        path: "insurance-category",
        loadChildren: '../app/modules/insurance-category/insurence-category.module#InsurenceCaregoryModule'
      },
      {
        path: "company",
        loadChildren: '../app/modules/company/company.module#CompanyModule'
      },

      {
        path: "documents",
        loadChildren: '../app/modules/documents/document.module#DocumentModule'
      },
      {
        path: "vehicle",
        loadChildren: '../app/modules/vehicle/vehicle.module#VehicleModule'
      },
      {
        path: "blog",
        loadChildren: '../app/modules/blog/blog.module#BlogModule'
      },
      {
        path: "policy-management",
        loadChildren: '../app/modules/policy-management/policyManagement.module#PolicyManagementModule'
      },
      {
        path: "product-management",
        loadChildren: '../app/modules/product-management/productManagement.module#ProductManagementModule'
      },
      {
        path: "module-management",
        loadChildren: '../app/modules/module-management/ModuleManagement.module#ModuleManagementModule'
      },
      {
        path: "claim-management",
        loadChildren: '../app/modules/claim/claim.module#ClaimModule'
      },
      {
        path: "inspection",
        loadChildren: '../app/modules/inspection/inspection.module#InspectionModule'
      },
      {
        path: "customer",
        loadChildren: '../app/modules/customer/customer.module#CustomerModule'
      },
      {
        path: "quote",
        loadChildren: '../app/modules/quote-management/quote.module#quoteModule'
      },
      {
        path: "transaction",
        loadChildren: '../app/modules/transaction-management/transaction.module#transactionModule'
      },
      {
        path: "report",
        loadChildren: '../app/modules/report-management/report.module#ReportModule'
      },
      {
        path: "tax-management",
        loadChildren: '../app/modules/tax-management/tax.module#TaxModule'
      },
      {
        path: "templates-management",
        loadChildren: '../app/modules/templates-management/templates.module#TemplatesModule'
      },


      {
        path: "change-password",
        component: ChangePasswordComponent
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
